import Joi from 'joi'
import _ from 'lodash'
import { FormInitialData, FormUserData } from '.'

export const validateInitialData = (initialData: any) => {
  const result = initialDataSchema.validate(initialData, { abortEarly: false })
  return {
    ...result,
    error: result.error
      ? _.mapValues(_.keyBy(result.error.details, 'context.key'), 'message')
      : undefined,
  }
}
export const validateUserData = (userData: any) => {
  const result = userDataSchema.validate(userData, { abortEarly: false })
  return {
    ...result,
    error: result.error
      ? _.mapValues(_.keyBy(result.error.details, 'context.key'), 'message')
      : undefined,
  }
}

const initialDataSchema = Joi.object<FormInitialData>({
  date: Joi.date().required().allow(null),
  orderTime: Joi.date().required(),

  orderer: Joi.string().required(),
  construction: Joi.string().required(),
  driver: Joi.string().required(),

  registrationNumber: Joi.string().required(),
  pumpType: Joi.string().required(),
  orderedPump: Joi.string().required(),
}).messages({
  'string.empty': 'To pole jest wymagane',
  'date.base': 'Pole musi być prawidłową datą',
})

const userDataSchema = Joi.object<FormUserData>({
  drive: Joi.number().optional(),
  hoursOfWork: Joi.number().required().positive(),
  wash: Joi.required(),
  kilometersAmount: Joi.number().required().positive(),
  pipelineAmount: Joi.number().required().positive().allow(0),
  deliveredPGAmount: Joi.number().required().positive().allow(0),
  arrivalTime: Joi.date().required(),
  departureTime: Joi.date().required(),
  cubicMetersAmount: Joi.number().required().positive(),

  pumpConfirmation: Joi.string().required(),
  additionalDriverInformation: Joi.string().allow(''),
  additionalSupervisorInformation: Joi.string().allow(''),

  reportEmail: Joi.string().allow('').optional(),

  concretingStart: Joi.date().optional(),
  concretingEnd: Joi.date().optional(),
}).messages({
  'string.empty': 'To pole jest wymagane',
  'date.base': 'Pole musi być prawidłową datą',
  'number.base': 'Pole musi być prawidłowym numerem',
  'number.positive': 'Pole musi być liczbą większą od zera',
  'any.required': 'To pole jest wymagane',
})
