import { DesktopDatePicker, DesktopTimePicker } from '@mui/lab'
import {
  Box,
  Button,
  Container,
  FormControl,
  InputLabel,
  MenuItem,
  TextField,
  Select as MuiSelect,
  Divider,
} from '@mui/material'
import {
  AdminFormInitialDataInput,
  AdminFormInput,
  AdminFormUserDataInput,
} from 'client/form/adminFormTypes'
import { nanoid } from 'nanoid'
import React, { useState } from 'react'
import { Form, PopulatedForm } from '../../../client/form'
import {
  validateInitialData,
  validateUserData,
} from '../../../client/form/formValidator'
import { User } from '../../../client/user'
import { Autocomplete } from '../../../components/Autocomplete'
import { Select } from '../../../components/Select'

interface Props {
  submitForm: (form: Form) => Promise<void>
  prevForm: PopulatedForm | null
  getDrivers: (search: string) => Promise<User[]>
  getOrderers: (search: string) => Promise<string[]>
  getConstructions: (search: string) => Promise<string[]>
  navigateHome: () => void
}

const initialFormData = () => ({
  date: new Date(),
  orderer: '',
  construction: '',
  driver: null,
  pumpType: '',
  registrationNumber: '',
  orderedPump: '',
  orderTime: new Date(),
})

export const FormCreation: React.FC<Props> = ({
  submitForm,
  prevForm,
  getDrivers,
  getOrderers,
  getConstructions,
  navigateHome,
}) => {
  const [errors, setErrors] = useState<{ [key: string]: string }>({})
  const [formInput, setFormInput] = useState<AdminFormInput>(
    prevForm
      ? parsePopulatedFormFromDb(prevForm)
      : {
          _id: nanoid(),
          userData: null,
          initialData: initialFormData(),
        },
  )

  const onInitialDataChange = (field: keyof AdminFormInitialDataInput) => (
    value: AdminFormInitialDataInput[typeof field] | null,
  ) =>
    setFormInput({
      ...formInput,
      initialData: { ...formInput.initialData, [field]: value },
    })

  console.log(formInput)
  console.log(errors)

  const onUserDataChange = (field: keyof AdminFormUserDataInput) => (
    value: AdminFormUserDataInput[typeof field] | null,
  ) =>
    setFormInput({
      ...formInput,
      userData: formInput.userData
        ? { ...formInput.userData, [field]: value }
        : null,
    })

  const onSubmit = () => {
    const initialDataResult = validateInitialData(formInput.initialData)
    if (initialDataResult.error) return setErrors(initialDataResult.error)
    if (formInput.userData !== null) {
      const userDataResult = validateUserData(formInput.userData)
      if (userDataResult.error) return setErrors(userDataResult.error)
    }

    setErrors({})
    submitForm(formInput as Form)
  }

  return (
    <Container maxWidth="md">
      <Box
        my={4}
        display="flex"
        flexDirection="column"
        alignItems="flex-start"
        gap={3}
      >
        <Box
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
          gap={3}
          width="100%"
        >
          <Box display="flex" gap={3} width="100%">
            <DesktopDatePicker
              label="Data"
              value={formInput.initialData.date}
              onChange={onInitialDataChange('date')}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  error={Boolean(errors?.date)}
                  helperText={errors?.date}
                />
              )}
            />
            <DesktopTimePicker
              label="Czas dostawy"
              value={formInput.initialData.orderTime}
              onChange={onInitialDataChange('orderTime')}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  error={Boolean(errors?.orderTime)}
                  helperText={errors?.orderTime}
                />
              )}
            />
          </Box>

          <Autocomplete
            value={formInput.initialData.orderer}
            getOptions={getOrderers}
            onChange={onInitialDataChange('orderer')}
            label="Zamawiający"
            error={errors.orderer}
          />
          <Autocomplete
            value={formInput.initialData.construction}
            getOptions={getConstructions}
            onChange={onInitialDataChange('construction')}
            label="Budowa"
            error={errors.construction}
          />
          <Box display="flex" gap={3} width="100%">
            <TextField
              fullWidth
              label="Typ pompy"
              value={formInput.initialData.pumpType}
              onChange={(e) => onInitialDataChange('pumpType')(e.target.value)}
              error={Boolean(errors?.pumpType)}
              helperText={errors?.pumpType}
            />
            <TextField
              fullWidth
              label="Nr rej."
              value={formInput.initialData.registrationNumber}
              onChange={(e) =>
                onInitialDataChange('registrationNumber')(e.target.value)
              }
              error={Boolean(errors?.registrationNumber)}
              helperText={errors?.registrationNumber}
            />
            <TextField
              fullWidth
              label="Zamówiona pompa"
              value={formInput.initialData.orderedPump}
              onChange={(e) =>
                onInitialDataChange('orderedPump')(e.target.value)
              }
              error={Boolean(errors?.orderedPump)}
              helperText={errors?.orderedPump}
            />
          </Box>
          <Box display="flex" gap={3} width="100%">
            <Select
              label="Kierowca"
              getOptions={getDrivers}
              getLabel={(o) => `${o.name} ${o.surname}`}
              getKey={(o) => o._id}
              getValue={(o) => o._id}
              value={formInput.initialData.driver}
              onChange={(value) => onInitialDataChange('driver')(value)}
              error={errors.driver}
              defaultOption={prevForm?.initialData.driver}
            />
          </Box>
        </Box>
        {formInput.userData && (
          <Box
            my={2}
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
            gap={3}
            width="100%"
          >
            <Divider sx={{ width: '100%', marginBottom: 2 }} />
            <Box display="flex" gap={3} width="100%">
              <TextField
                fullWidth
                label="Ilość m³"
                type="number"
                value={formInput.userData.cubicMetersAmount}
                onChange={(e) =>
                  onUserDataChange('cubicMetersAmount')(e.target.value)
                }
                error={Boolean(errors?.cubicMetersAmount)}
                helperText={errors?.cubicMetersAmount}
              />

              <TextField
                fullWidth
                label="Czas pracy"
                type="number"
                value={formInput.userData.hoursOfWork}
                onChange={(e) =>
                  onUserDataChange('hoursOfWork')(e.target.value)
                }
                error={Boolean(errors?.hoursOfWork)}
                helperText={errors?.hoursOfWork}
              />
              <TextField
                fullWidth
                label="Ilość km"
                type="number"
                value={formInput.userData.kilometersAmount}
                onChange={(e) =>
                  onUserDataChange('kilometersAmount')(e.target.value)
                }
                error={Boolean(errors?.kilometersAmount)}
                helperText={errors?.kilometersAmount}
              />
            </Box>
            <Box display="flex" gap={3} width="100%">
              <FormControl fullWidth>
                <InputLabel id="wash-label">Mycie na budowie</InputLabel>
                <MuiSelect
                  label="Mycie na budowie"
                  value={formInput.userData.wash ? 1 : 0}
                  labelId="wash-label"
                  onChange={(e) =>
                    onUserDataChange('wash')(Boolean(e.target.value))
                  }
                >
                  <MenuItem value={1}>Tak</MenuItem>
                  <MenuItem value={0}>Nie</MenuItem>
                </MuiSelect>
              </FormControl>
              <TextField
                fullWidth
                label="Rurociąg (stal/guma) ilość mb."
                type="number"
                value={formInput.userData.pipelineAmount ?? ''}
                onChange={(e) =>
                  onUserDataChange('pipelineAmount')(e.target.value)
                }
                error={Boolean(errors?.pipelineAmount)}
                helperText={errors?.pipelineAmount}
              />
              <TextField
                fullWidth
                label="Przestawienie pompy (ilość)"
                type="number"
                value={formInput.userData.deliveredPGAmount ?? ''}
                onChange={(e) =>
                  onUserDataChange('deliveredPGAmount')(e.target.value)
                }
                error={Boolean(errors?.deliveredPGAmount)}
                helperText={errors?.deliveredPGAmount}
              />
            </Box>
            <Box display="flex" gap={3} width="100%">
              <DesktopTimePicker
                label="Czas przybycia"
                value={formInput.userData.arrivalTime}
                onChange={onUserDataChange('arrivalTime')}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    helperText={errors?.arrivalTime}
                    error={Boolean(errors?.arrivalTime)}
                  />
                )}
              />
              <DesktopTimePicker
                label="Czas wyjazdu"
                value={formInput.userData.departureTime}
                onChange={onUserDataChange('departureTime')}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    helperText={errors?.departureTime}
                    error={Boolean(errors?.departureTime)}
                  />
                )}
              />
            </Box>
            <Box display="flex" gap={3} width="100%">
              <TextField
                fullWidth
                label="Uwagi kierowcy"
                type="text"
                multiline
                value={formInput.userData.additionalDriverInformation}
                onChange={(e) =>
                  onUserDataChange('additionalDriverInformation')(
                    e.target.value,
                  )
                }
              />
            </Box>
            <Box display="flex" gap={3} width="100%">
              <TextField
                fullWidth
                label="Uwagi kierownika budowy"
                type="text"
                multiline
                value={formInput.userData.additionalSupervisorInformation}
                onChange={(e) =>
                  onUserDataChange('additionalSupervisorInformation')(
                    e.target.value,
                  )
                }
              />
            </Box>
            <Box display="flex" gap={3} width="100%">
              <TextField
                fullWidth
                label="Email do przesłania raportu"
                type="text"
                multiline
                value={formInput.userData.reportEmail}
                onChange={(e) =>
                  onUserDataChange('reportEmail')(e.target.value)
                }
              />
            </Box>
          </Box>
        )}
        <Box display="flex" justifyContent="space-between" width="100%">
          <Button onClick={navigateHome} color="inherit">
            Powrót
          </Button>
          <Button onClick={onSubmit} variant="contained">
            Zapisz
          </Button>
        </Box>
      </Box>
    </Container>
  )
}

const parsePopulatedFormFromDb = (form: PopulatedForm): AdminFormInput => ({
  ...form,
  initialData: {
    ...form.initialData,
    driver: form.initialData.driver._id,
    date: form.initialData.date ? new Date(form.initialData.date) : null,
    orderTime: new Date(form.initialData.orderTime),
  },
  userData: form.userData
    ? {
        ...form.userData,
        arrivalTime: form.userData.arrivalTime
          ? new Date(form.userData.arrivalTime)
          : null,
        departureTime: new Date(form.userData.departureTime),
      }
    : null,
})
